import React, { useState, useEffect } from 'react';

// Hardcoded items from the CSV
const INVENTORY_ITEMS = [
"PACK SAHARA BLACK",
"SAND_",
"GRAVEL_",
"Sack Sand",
"Sack Gravel",
"BISTAY",
"CHB 4",
"CHB 5",
"CHB 6",
"EAGLE CEMENT_",
"EAGLE CEMENT (TRUCKLOAD)",
"REPUBLIC CEMENT",
"HOLCIM CEMENT",
"BORAL UV88 POWDER",
"BI STEEL MATTING #8 MAKAPAL",
"SKIMCOAT FORMEX WHITE",
"LATASKIM WHITE 20kg",
"FORTRESS SKIMCOAT",
"ABC SKIMCOAT ZEMCOAT",
"PATCHING COMPOUND ORDINARY",
"ABC GROUT BLACK",
"ABC GROUT BEIGE",
"ABC GROUT BROWN",
"ABC GROUT GRAY",
"ABC GROUT WHITE",
"ABC GYPSUM PUTTY GALLON",
"ABC GYPSUM PUTTY PAIL",
"BOSTIK FIX ALL TILE ADHESIVE",
"FORTRESS TILE ADHESIVE RED",
"ABC TILE ADHESIVE",
"ABC TILE ADHESIVE HEAVY DUTY",
"FORTRESS SKIM COAT",
"ZEMCOAT",
"ABC GAL REDIFEX",
"STEEL BAR 7mm",
"STEEL BAR 10mm",
"STEEL BAR 12mm",
"STEEL BAR 16mm",
"DEFORM BAR 20MM",
"ANGLE BAR 1/8*1 (BLUE)",
"ANGLE BAR 3/16*1 (RED)",
"ANGLE BAR 3/16*1.5 (RED)",
"ANGLE BAR 3/16*2 (RED)",
"ANGLE BAR 1/4*1 (GREEN)",
"ANGLE BAR 1/4*1.5 (GREEN)",
"ANGLE BAR 1/4*2 (GREEN)",
"TUBULAR 3/4*3/4 4-4.5kg (White)",
"TUBULAR 1*1 4.8-5.3kg (White)",
"TUBULAR 1.5*1.5 7.3-8kg (White)",
"TUBULAR 1*2 7.7-8.2kg (White)",
"TUBULAR 2*2 9.8-10.5kg (White)",
"TUBULAR 2*3 12.8-13.5kg  (White)",
"TUBULAR 2*4 14-14.5kg (White)",
"TUBULAR 2*6 21.5-22kg  (White)",
"TUBULAR 3/4*3/4 2.7-3.2kg (Yellow)",
"TUBULAR 1*1 3.7-4.2kg (Yellow)",
"TUBULAR 1.5*1.5 5.5-6kg (Yellow)",
"TUBULAR 1*2 5.7-6.2kg(Yellow)",
"TUBULAR 2*2 8-8.5kg (Yellow)",
"TUBULAR 2*3 10.1-10.6kg (Yellow)",
"TUBULAR 2*4 11.8-12.5kg (Yellow",
"C-PURLINS 2*3 8.5-9kg (White)",
"C-PURLINS 2*3 7.3kg-7.8kg (Yellow)",
"C-PURLINS 2*4 10-10.5kg (White)",
"C-PURLINS 2*4 9-9.5kg (Yellow)",
"BI C-PURLINS 2*3 (White) WH",
"C PURLINS 2*6 14- 14.5kg (White)",
"C PURLINS 2*3 16.3kg (White) 2.0mm",
"ROUND BAR 10",
"ROUND BAR 12",
"SQUARE BAR 10mm WHITE",
"SQUARE BAR 12mm",
"FLAT BAR 1/4*1",
"FLAT BAR 1/4*1.5",
"FLAT BAR 1/4*2",
"FLAT BAR 3/16*1",
"FLAT BAR 3/16*1.5",
"FLAT BAR 3/16*2",
"GI PIPE S-20 1/2",
"GI PIPE S-20 3/4",
"GI PIPE S-20 1",
"GI PIPE S-20 1.25",
"GI PIPE S-20 1.5",
"GI PIPE S-20 2",
"GI PIPE S-20 3  ",
"GI PIPE S-40 1/2",
"GI PIPE S-40 3/4",
"GI PIPE S-40 1",
"GI PIPE S-40 1.25",
"GI PIPE S-40 1.5",
"GI PIPE S-40 2",
"GI PIPE S-40 3",
"GI WIRE #16 22KLS",
"GI WIRE #16 20KLS",
"GI PLAIN 3*8 .4 MAKAPAL",
"GI PLAIN 4*8 .4",
"GI PLAIN 4*8 GREEN",
"GI PLAIN 4*8 RED",
"LIGHT FRAME CARRYING CHANNEL (BLUE)",
"LIGHT FRAME DOUBLE FURRING (BLUE)",
"LIGHT FRAME METAL STUD (BLUE)",
"LIGHT FRAME METAL TRACK (BLUE)",
"LIGHT FRAME WALL ANGLE",
"ASIALUME BLUE 8FT",
"ASIALUME BLUE 10FT",
"ASIALUME BLUE 12FT",
"ASIALUME RED 8",
"ASIALUME RED 10",
"ASIALUME RED 12",
"GI YERO 8FT",
"GI YERO 10FT",
"GI YERO 12FT",
"COLORED CORRUGATED 8FT (GREEN)",
"COLORED CORRUGATED 10FT (GREEN)",
"COLORED CORRUGATED 12FT (GREEN)",
"COLORED CORRUGATED 8FT (RED)",
"COLORED CORRUGATED 10FT (RED)",
"COLORED CORRUGATED 12FT (RED)",
"FIBER GLASS 8",
"FIBER GLASS 10",
"FIBER GLASS 12",
"LONGSPAN 12FT .4 (GREEN)",
"LONGSPAN 12FT .4 (RED)",
"LONGSPAN 14FT .4 (RED)",
"LONGSPAN 14FT .4 (GREEN)",
"FLASHING BLUE",
"FLASHING BROWN",
"FLASHING GREEN",
"FLASHING KAPAL",
"FLASHING RED",
"RIDGE ROLL BLUE",
"RIDGE ROLL BROWN",
"RIDGE ROLL GREEN",
"RIDGE ROLL PLAIN MAKAPAL",
"RIDGE ROLL RED",
"GUTTER BLUE",
"GUTTER BROWN",
"GUTTER PLAIN",
"INSULATION 5MM 1SIDED",
"INSULATION 5MM 2SIDED",
"INSULATION 10MM 1SIDED",
"INSULATION 10MM 2SIDED",
"MARINE PLYWOOD 1/4 CHINA (RED)",
"MARINE PLYWOOD 1/2 CHINA (RED)",
"MARINE PLYWOOD 3/4 CHINA (RED)",
"MARINE PLYWOOD 1/4 LOCAL (BLACK)",
"MARINE PLYWOOD 1/2 LOCAL (BLACK)",
"MARINE PLYWOOD 3/4 LOCAL (BLACK)",
"ORD. PLYWOOD 1/4 CHINA (GREEN)",
"ORD. PLYWOOD 1/2 CHINA (GREEN)",
"ORD. PLYWOOD 3/4 CHINA (GREEN)",
"ORD. PLYWOOD 1/4 LOCAL (SILVER)",
"ORD. PLYWOOD 1/2 LOCAL (SILVER)",
"ORD. PLYWOOD 3/4 LOCAL (SILVER)",
"PHENOLIC BOARD 1/2 (8mm only-S)",
"PHENOLIC BOARD 1/2 (7mm only-G)",
"PHENOLIC BOARD 1/2",
"PHENOLIC BOARD 3/4 (15mm-S)",
"PHENOLIC BOARD 3/4 (14mm -G)",
"PLYBOARD 3/4 BLUE",
"PLYBOARD 3/4 RICHMOND (BLACK)",
"HARDIFLEX LITE 3.5MM",
"HARDIFLEX 4.5",
"HARDIFLEX 6MM",
"HARDIFLEX 9MM",
"HARDIFLEX 12",
"GYPSUM BOARD 9MM (GYPROC)",
"GYPSUM BOARD 12MM (GYPROC)",
"GYPSUM BOARD 12MM (KNAUF)",
"GYPSUM BOARD 9MM (KNAUF)",
"GARDNER 4.5",
"GARDNER 3.5",
"GARDNER SANEPA 1*10*8",
"GARDNER SANEPA 1*12*8",
"CORNIZA 2*8",
"CORNIZA 2*10",
"CORNIZA 2*12",
"CORNIZA 3*8",
"CORNIZA 3*10",
"CORNIZA 3*12",
"CORNIZA 4*8",
"CORNIZA 4*10",
"CORNIZA 4*12",
"CANADIAN 1.5*2*8",
"CANADIAN 1.5*2*10",
"CANADIAN 1.5*2*12",
"CANADIAN 1*2*8",
"CANADIAN 1*2*10",
"CANADIAN 1*2*12",
"CANADIAN 2*2*8",
"CANADIAN 2*2*10",
"CANADIAN 2*2*12",
"CANADIAN 2*3*8",
"CANADIAN 2*3*10",
"CANADIAN 2*3*12",
"CANADIAN 2*4*8",
"CANADIAN 2*4*10",
"CANADIAN 2*4*12",
"COCO LUMBER",
"KD1*2*8",
"KD 1*2*10",
"KD 1*2*12",
"KD 1.5*2*8",
"KD1.5*2*10",
"KD1.5*2*12",
"KD1*3*8",
"KD 1*3*10",
"KD 1*3*12",
"KD 1*4*8",
"KD1*4*10",
"KD 1*4*12",
"KD1*6*8",
"KD 1*6*10",
"KD 1*6*12",
"KD1*8*8",
"KD1*8*10",
"KD 1*10*8",
"KD 1*10*10",
"KD 1*10*12",
"KD 1*12*8",
"KD 1*12*10",
"KD 1*12*12",
"KD2*2*8",
"KD2*2*10",
"KD2*2*12",
"KD2*3*8",
"KD2*3*10",
"KD2*3*12",
"KD2*4*8",
"KD2*4*10",
"KD2*4*12",
"KD2*6*8",
"KD2*6*10",
"KD2*6*12",
"KD 2*8*8",
"KD2*8*10",
"KD2*8*12",
"KD2*10*8",
"KD2*10*10",
"KD2*10*12",
"KD2*12*8",
"KD2*12*10",
"KD2*12*12",
"L1/2*1*8",
"L1/2*1*10",
"L1/2*1*12",
"L1/2*2*8",
"L1/2*2*10",
"L1/2*2*12",
"L1*1*8",
"L1*1*10",
"L1*1*12",
"QUARTER ROUND 8",
"QUARTER ROUND 10",
"QUARTER ROUND 12",
"HALF ROUND 8",
"HALF ROUND 10",
"HALF ROUND 12",
"BS1404 BOYSEN A/C THALO BLUE",
"BS1405 BOYSEN A/C THALO GREEN",
"BS1406 BOYSEN A/C RAW SIENNA",
"BS1407 BOYSEN A/C TOULIDINE RED",
"BS1408 BOYSEN A/C BURNT SIENNA",
"BS1409 BOYSEN A/C BURNT UMBER",
"BS1466 A/C HANSA YELLOW",
"BS1477 BOYSEN A/C VENETIAN RED",
"BS1490 BOYSEN A/C LAMPBLACK",
"BS2303 BOYSEN T/C THALO GREEN",
"BS2306 BOYSEN TINTING COLOR THALO BLUE",
"BS2313 BOYSEN T/C BURNT SIENNA",
"BS2314 BOYSEN T/C RAW SIENNA",
"BS2315 BOYSEN T/C BULLETIN RED",
"BS2317 BOYSEN T/C VENETIAN RED",
"BS2318 BOYSEN T/C LAMPBLACK",
"BS2319 BOYSEN T/C HANSA YELLOW",
"BS600 QDE WHITE",
"BS642 QDE SKY BLUE",
"BS671 QDE INTERNATIONAL RED",
"BS676 QDE ORANGE",
"BS680 QDE BROWN",
"BS690 QDE BLACK",
"BS658 QDE EMERALD",
"BS661 QDE LEMON YELLOW",
"BS640 QDE ROYAL B",
"BL1404 ACRY-COLOR THALO BLUE",
"BL1405 A/C THALO GREEN",
"BL1406 BOYSEN A/C RAW SIENNA",
"BL1407 BOYSEN A/C TOULIDINE RED",
"BL1408 BOYSEN A/C BURNT SIENNA",
"BL1409 BOYSEN A/C BURNT UMBER",
"BL1466 A/C HANZA YELLOW",
"BL1477 A/C VENETIAN RED",
"BL1490 LAMPBLACK",
"BL1205 LACQUER FLO",
"BL1250 CLEAR GLOSS",
"BL1253 DEAD FLAT LACQUER",
"BL1300 AUTO LACQUER WHITE",
"BL1254 SANDING SEALER",
"BL2705 WOODSTAIN MAPLE",
"BL2700 WOODSTAIN WALLNUT",
"BL2707 WOODSTAIN MAHOGANY",
"BL305 AUTO LACQUER PRIMER",
"BL310 BOYSEN RED OXIDE",
"BL600 QDEWHITE",
"BL640 ROYAL BLUE",
"BL656 JADE GREEN",
"BL680 QDE BROWN",
"BL690 QDE BLACK",
"BL700 ACRYLIC EMULSION",
"BL701 FLAT LATEX",
"BL710 GLOSS LATEX",
"BL715 SEMI GLOSS LATEX",
"BL75 SILVER ALUMINUM FINISH",
"BL791 PERMACOAT FLAT LATEX",
"BL800 FLAT WALL ENAMEL",
"BL311 GLAZING PUTTY",
"BL642 QDE SKY BLUE",
"BL658 QDE EMERALD GREEN",
"BL661 QDE LEMON YELLOW",
"BL671 QDE INTERNATIONAL RED",
"BL676 QDE ORANGE",
"BL7311 MASONRY PUTTY",
"BG691 QDE FLAT BLACK",
"BG1205 LACQUER FLO",
"BG1250 CLEAR GLOSS LACQUER",
"BG1253 DEAD FLAT LAQUER",
"BG1254 SANDING SEALER",
"BG1258 WATER WHITE CLEAR",
"BG1300 AUTO LACQUER WHITE",
"BG1705 ACRYTEX PRIMER",
"BG1711 ACRYTEX CAST",
"BG1750 ACRYTEX REDUCER",
"BG200 SEMI GLOSS ENAMEL",
"BG2520 ROOFGUARD SAMAR",
"BG2540 ROOFGUARD BLUE",
"BG2550 ROOFGUARD GREEN",
"BG2570 ROOFGUARD RED",
"BG2700 WOODSTAIN WALLNUT",
"BG2705 WOODSTAIN MAPLE",
"BG2707 WOODSTAIN MAHOGANY",
"BG305 AUTO.LACQUER PRIMER",
"BG306 AUTO LACQUER S.PUTTY",
"BG310 GALLON RED OXIDE",
"BG3101 PERMATEX TEXTURED",
"BG340 PAINT THINNER",
"BG50 LACQUERTHINNER",
"BG600 QDEWHITE",
"BG640 QDE ROYAL BLUE",
"BG642 QDE SKY BLUE",
"BG676 QDE ORANGE",
"BG680 QDE BROWN",
"BG690 QDE BLACK",
"BG700 ACRYLIC EMULSION",
"BG701 PERMACOAT FLAT LATEX",
"BG710 GLOSS LATEX",
"BG715 SEMI GLOSS LATEX",
"BG7311 MASONRY PUTTY",
"BG7760 FLEXIBOND",
"BG791 PERMACOAT FLAT LATE",
"BG800 FLAT WALL ENAMEL",
"BG311 GLAZING PUTTY",
"BG658 EMERALD GREEN",
"BG661 QDE LEMON YELLOW",
"BG671 QDE INTERNATIONAL RED",
"BT1705 ACRYTEX PRIMER",
"BT2520 ROOFGUARD SAMAR",
"BT2540 ROOF PAINT PACIFIC BLUE",
"BT2550 ROOFGUARD GREEN",
"BT2570 ROOFGUARD SPANISH RED",
"BT50 LACQUER THINNER",
"BT600 BOYSEN QDE WHITE",
"BT701 BOYSEN FLAT LATEX",
"BT710 BOYSEN GLOSS LATEX",
"BT715 SEMI GLOSS",
"BT7760 FLEXIBOND",
"BT800 FLAT WALL ENAMEL",
"BT1750 ARCYLTEX REDUCER",
"GROS633  ROS CHOCO BROWN",
"GROS853 ROS SUGAR CANE",
"GROS559 ROS  BRIDAL GOWN",
"GROS7000 ROS  PREPA WHITE",
"PYLOX 07 MEDIUM YELLOW",
"PYLOX 12 ANTI-RUST BROWN",
"PYLOX 17 DEEP RED",
"PYLOX 18 DEEP VIOLET",
"PYLOX 22 SKY BLUE",
"PYLOX 23 DIAMOND BLUE",
"PYLOX 24 ISUZU BLUE",
"PYLOX 28 DARK GREEN",
"PYLOX 33 LIGHT GREEN",
"PYLOX 42 SILVER",
"PYLOX 45 GRAY",
"PYLOX 47 MATT BLACK",
"PYLOX 48 GLOSS BLACK",
"PYLOX 52 BROWN",
"PYLOX 55 WOOD BEIGE",
"PYLOX 700 METALIC BRIGHT",
"LITTER N-950 FAST DRY ENAMEL",
"LITTER N-965 FAST DRY MAPLE",
"GAL N-1540 FLAT LATEX",
"GAL N-1541 GLOSS LATEX",
"GAL N-950 FAST DRY ENAMEL",
"GAL N-965 FAST DRY MAPLE",
"NATION 800 GALLON FLATWALL ENAMEL",
"NATION 800 PAIL FLATWALL ENAMEL",
"TIN N-1540 FLAT LATEX",
"TIN N-1541 GLOSS LATEX",
"LIT COAT SAVER SOLO GLOSS",
"COAT SAVER GALLON SOLO FLAT LATEX",
"COAT SAVER GALLON SOLO GLOSS",
"TIN C-88 COAT SAVER SOLO FLAT LATEX",
"TIN C-99 COAT SAVER SOLO GLOSS LATEX",
"BLUE PIPE ASIA 1/2",
"BLUE PIPE ASIA 3/4",
"BLUE PIPE OMEGA 1",
"BLUE PIPE NELTEX 1/2",
"BLUE PIPE NELTEX 3/4",
"BLUE PIPE NELTEX 1",
"[ORD] B. COUPLING 1/2",
"[ORD] B. COUPLING 3/4",
"[ORD] B.COUPLING 1",
"[ORD]B. COUPLING RED 1*3/4",
"[ORD]B. COUPLING RED 3/4*1/2",
"[ORD]B. ELBOW PLAIN 1/2",
"[ORD] B. ELBOW PLAIN 3/4",
"[ORD] B. ELBOW PLAIN 1",
"[ORD]B. ELBOW W/T 1/2",
"[ORD]B. ELBOW W/T 3/4",
"[ORD]B. ELBOW W/T 1",
"[ORD]B. TEE 1/2 PLAIN",
"[ORD]B. TEE 3/4 PLAIN",
"[ORD]B. TEE PLAIN 1",
"[ORD]B. TEE W/T 1/2",
"BLUE FIT. TEE THREAD 3/4",
"[ORD] B. TEE W/T 1",
"[ORD]B. FEMALE ADAP 3/4",
"[ORD]B. FEMALE ADAP 1",
"[ORD]B. MALE ADAP 1/2",
"[ORD]B. MALE ADAP 3/4",
"[ORD]B. MALE ADAP 1",
"[ORD]B. CAP 3/4",
"[ORD]B. END CAP 3/4",
"BLUE ORD END CAP 1",
"[EM] B. ELBOW 1/2 W/T",
"[EM] B. ELBOW PLAIN 1/2",
"[EM] B. TEE 1/2",
"[EM] B. TEE 1/2 WT",
"[NELTEX] B. FIT ELBOW 1/2 PLAIN",
"[NELTEX] B. FIT M.ADAPTOR 1/2",
"[NELTEX] B. FIT NELTEX COUPLING 1/2",
"[NELTEX] B. FIT NELTEX F.ADAPT 1/2_",
"[NELTEX] B. FIT TEE 1/2 PLAIN",
"RAINBOW HOSE 1/2",
"RAINBOW HOSE 3/4",
"RAINBOW HOSE 5/8",
"GATE VALVE 1/2",
"GATE VALVE 3/4",
"BALL VALVE 3/4 PVC",
"BALL VALVE PLASTIK 1/2",
"SWIVEL CLAMP 1.5 SWING",
"ICONS PPR PIPE 1 PN20",
"ICONS PPR PIPE 1/2 PN20",
"ICONS PPR PIPE 3/4 PN20",
"PPR COUPLING 1",
"PPR COUPLING 1/2",
"PPR COUPLING 3/4",
"PPR COUPLING REDUCER 1*1/2",
"PPR COUPLING REDUCER 1*3/4",
"PPR COUPLING REDUCER 3/4*1/2",
"PPR ELBOW 45 1",
"PPR ELBOW 45 1/2",
"PPR ELBOW 45 3/4",
"PPR ELBOW 90 1",
"PPR ELBOW 90 1/2",
"PPR ELBOW 90 3/4",
"PPR ELBOW RED 1*1/2",
"PPR ELBOW RED 1*3/4",
"PPR ELBOW RED 3/4*1/2",
"PPR END CAP 1/2",
"PPR END CAP 3/4",
"PPR EQUAL TEE 1",
"PPR EQUAL TEE 1/2",
"PPR EQUAL TEE 3/4",
"PPR FEMALE ADAPTOR 1",
"PPR FEMALE ADAPTOR 1/2",
"PPR FEMALE ADAPTOR 3/4",
"PPR FEMALE ELBOW 1",
"PPR FEMALE ELBOW 1/2",
"PPR FEMALE ELBOW 3/4",
"PPR FEMALE TEE 1",
"PPR FEMALE TEE 1/2",
"PPR FEMALE TEE 3/4",
"PPR FUSHION 1/2 TO 1",
"PPR GATE VALVE 1",
"PPR GATE VALVE 1/2",
"PPR GATE VALVE 3/4",
"PPR MALE ADAPTOR 1",
"PPR MALE ADAPTOR 1/2",
"PPR MALE ADAPTOR 3/4",
"PPR MALE ELBOW 1",
"PPR MALE ELBOW 1/2",
"PPR MALE ELBOW 3/4",
"PPR MALE TEE 1/2",
"PPR MALE TEE 3/4",
"PPR PLUG 1",
"PPR PLUG 1/2",
"PPR PLUG 3/4",
"PPR TEE REDUCER 1*3/4",
"PPR TEE REDUCER 1/2*3/4",
"17PPR END CAP 1",
"BLACK PIPE 2",
"BLACK PIPE 3",
"BLACK PIPE 4",
"BLACK CLEAN OUT 2",
"BLACK CLEAN OUT 3",
"BLACK CLEAN OUT 4",
"BLACK COUPLING 2",
"BLACK COUPLING 3",
"BLACK COUPLING 4",
"BLACK ELBOW 1/4B 2",
"BLACK ELBOW 1/4B 3",
"BLACK ELBOW 1/4B 4",
"BLACK ELBOW 1/8B 2",
"BLACK ELBOW 1/8B 3",
"BLACK ELBOW 1/8B 4",
"BLACK TEE 3*2",
"BLACK TEE 3*3",
"BLACK TEE 4*2",
"BLACK TEE 4*3",
"BLACK TEE 4*4",
"BLACK WYE 2*2",
"BLACK WYE 3*2",
"BLACK WYE 3*3",
"BLACK WYE 4*2",
"BLACK WYE 4*3",
"BLACK WYE 4*4",
"BLACK P-TRAP 2",
"ORDINARY ORANGE PIPE 2",
"ORDINARY ORANGE PIPE 3",
"ORDINARY ORANGE PIPE 4",
"ORDINARY ORANGE PIPE 6",
"SOLAR BUSHING REDUCER 3*2",
"SOLAR BUSHING REDUCER 4*2",
"SOLAR BUSHING REDUCER 4*3",
"SOLAR P-TRAP 2",
"SOLID JADE CLEANOUT 2",
"SOLID JADE CLEANOUT 3",
"SOLID JADE CLEANOUT 4",
"SOLID JADE COUPLING 2",
"SOLID JADE COUPLING 3",
"SOLID JADE COUPLING 4",
"SOLID JADE ELBOW 1/4B 2",
"SOLID JADE ELBOW 1/4B 3",
"SOLID JADE ELBOW 1/4B 4",
"SOLID JADE ELBOW 1/8B 2",
"SOLID JADE ELBOW 1/8B 3",
"SOLID JADE ELBOW 1/8B 4",
"SOLID JADE TEE 2*2",
"SOLID JADE TEE 3*2",
"SOLID JADE TEE 3*3",
"SOLID JADE TEE 4*2",
"SOLID JADE TEE 4*3",
"SOLID JADE TEE 4*4",
"SOLID JADE WYE 2*2",
"SOLID JADE WYE 3*2",
"SOLID JADE WYE 3*3",
"SOLID JADE WYE 4*2",
"SOLID JADE WYE 4*3",
"SOLID JADE WYE 4*4",
"ATLANTA PIPE 2",
"ATLANTA PIPE 3",
"ATLANTA PIPE 4",
"ATLANTA CLEAN-OUT 2",
"ATLANTA CLEAN-OUT 3",
"ATLANTA CLEAN-OUT 4",
"ATLANTA COUPLING 2",
"ATLANTA COUPLING 3",
"ATLANTA COUPLING 4",
"ATLANTA ELBOW 1/4B 2",
"ATLANTA ELBOW 1/4B 4",
"ATLANTA ELBOW 1/8B 2",
"ATLANTA ELBOW 1/8B 3",
"ATLANTA ELBOW 1/8B 4",
"ATLANTA P-TRAP 2",
"ATLANTA TEE 2*2",
"ATLANTA TEE 3*2",
"ATLANTA TEE 3*3",
"ATLANTA TEE 4*3",
"ATLANTA TEE 4*4",
"ATLANTA WYE 2*2",
"ATLANTA WYE 3*2",
"ATLANTA WYE 3*3",
"ATLANTA WYE 4*2",
"ATLANTA WYE 4*3",
"NELTEX PVC PIPE 2 S-1000",
"NELTEX PVC  PIPE 3 S-1000",
"NELTEX PVC  PIPE 4 S-1000",
"NELTEX PVC  PIPE 6 S-1000",
"NELTEX CLEAN-OUT 2",
"NELTEX CLEAN-OUT 3",
"NELTEX CLEAN-OUT 4",
"NELTEX COUPLING 2",
"NELTEX COUPLING 3",
"NELTEX COUPLING 4",
"NELTEX ELBOW 1/4B 2",
"NELTEX ELBOW 1/4B 3",
"NELTEX ELBOW 1/4B 4",
"NELTEX ELBOW 1/4B 6",
"NELTEX ELBOW 1/8*3",
"NELTEX ELBOW 1/8*4",
"NELTEX ELBOW 1/8*6",
"NELTEX ELBOW 1/8B 2",
"NELTEX P-TRAP 2",
"NELTEX PIPE 2S-600",
"NELTEX PIPE 3 S-600",
"NELTEX PIPE 4 S-600",
"NELTEX TEE 2*2",
"NELTEX TEE 3*2",
"NELTEX TEE 3*3",
"NELTEX TEE 4*2",
"NELTEX TEE 4*3",
"NELTEX TEE 4*4",
"NELTEX TEE 6*6",
"NELTEX WYE 2*2",
"NELTEX WYE 3*2",
"NELTEX WYE 3*3",
"NELTEX WYE 4*2",
"NELTEX WYE 4*3",
"NELTEX WYE 4*4",
"NELTEX WYE 6*4",
"DRILL BIT 1/8 FOR STEEL",
"DRILL BIT 5/32 FOR STEEL",
"DRILL BIT 3/16 FOR STEEL",
"DRILL BIT 1/4 FOR STEEL",
"DRILL BIT 3/8 FOR STEEL",
"DRILL BIT 1/2 FOR STEEL",
"DRILL BIT 5/8 FOR STEEL",
"DRILL BIT 1/8 FOR STONE",
"DRILL BIT 5/32 FOR STONE",
"DRILL BIT 3/16 FOR STONE",
"DRILL BIT 1/4 FOR STONE",
"DRILL BIT 1/2 FOR STONE",
"DRILL BIT 3/8 MASONRY",
"DRILL BIT 5/8 MASONRY",
"BLIND RIVETS 1/8*3/8",
"BLIND RIVETS 1/8*1/2",
"BLIND RIVETS 1/8*3/4",
"BLIND RIVETS 1/8*1",
"BLIND RIVETS 5/32*3/8",
"BLIND RIVETS 5/32*1/2",
"BLIND RIVETS 5/32*3/4",
"BLIND RIVETS 5/32*1",
"BLIND RIVETS 3/16*3/8",
"BLIND RIVETS 3/16*1/2",
"BLIND RIVETS 3/16*1",
"BLACK SCREW 1 METAL",
"BLACK SCREW 1.5 METAL",
"BLACK SCREW 2 METAL",
"BLACK SCREW 1 WOOD",
"BLACK SCREW 1.5 WOOD",
"BLACK SCREW 2 WOOD",
"PC TEXSCREW 1.5 METAL",
"PC TEXSCREW 1 METAL",
"PC TEXSCREW 2 METAL",
"PC TEXSCREW 2.5 METAL",
"PC TEXSCREW 3 METAL",
"PC TEXSCREW 1 WOOD",
"PC TEXSCREW 1.5 WOOD",
"PC TEXSCREW 2 WOOD",
"PC TEXSCREW 2.5 WOOD",
"METAL SCREW 10*2",
"CUP HOOKGOLD 1/12",
"SCREW BIT",
"PIANO SCREW",
"LOGSCREW 1/4*2.5",
"LOGSCREW 3/8*2.5",
"FLAT HEAD METAL SCREW 8*1.5",
"DYNABOLT 1/4*50",
"DYNABOLT 3/8*75",
"EXPANSION SHIELD 1/4 LONG",
"EXPANSION SHIELD 3/8",
"GI WASHER 3/8",
"GRIP ANCHOR 3/8",
"TEXSCREW ADAPTOR",
"THREADED ROD 3/8",
"METAL LOCK NUT 1/2",
"METAL LOCK NUT 3/4",
"METAL LOCK NUT 1",
"NUT 3/8",
"TOX5",
"TOX6",
"TOX8",
"TOX10",
"COMMON WIRE NAIL 1",
"COMMON WIRE NAIL 1.5",
"COMMON WIRE NAIL 2",
"COMMON WIRE NAIL 2.5",
"COMMON WIRE NAIL 3",
"COMMON WIRE NAIL 4",
"FINISHING NAIL 1",
"FINISHING NAIL 1.5",
"FINISHING NAIL 2",
"FINISHING NAIL 2.5",
"FINISHING NAIL 3",
"HARDINAIL",
"CONCRETE NAIL 1",
"CONCRETE NAIL 1.5",
"CONCRETE NAIL 2",
"CONCRETE NAIL 2.5",
"CONCRETE NAIL 3",
"CONCRETE NAIL 4",
"ROOFING NAIL",
"CYLINDRICAL HINGES 3/8",
"CYLINDRICAL HINGE 1/2",
"CYLINDRICAL HINGE 3/4",
"CYLINDRICAL HINGE 1",
"LOOSE PIN HINGES 3",
"HINGES ORD 1*3",
"PIANO HINGES",
"PC CONCEALED HINGES 1",
"PC CONCEALED HINGES 2",
"PC CONCEALED HINGES 3",
"CONCEALED HINGES #1 SOFT CLOSE",
"CONCEALED HINGES #2 SOFT CLOSE",
"CONCEALED HINGES #3 SOFT CLOSE",
"HARDWARE CLOTH",
"ALUMINUM SCREEN 3FT",
"ALUMINUM SCREEN 4FT",
"WELDED WIRE 1/2*3",
"WELDED WIRE 1/2*4",
"WELDED WIRE 1*3FT",
"WELDED WIRE 1*4FT",
"AMAZON SCREEN 1/8*3FT",
"AMAZON SCREEN 1/8*4FT",
"AMAZON SCREEN 3/8*3FT",
"AMAZON SCREEN 3/8*4FT",
"WELDING HOLDER 300 AMP",
"WELDING ROD 6011 - 1/8 (NIHON)",
"WELDING ROD 6013 - 1/8 N-55 (NIHON)",
"WELDING ROD 6013 - 3/32 (SPECIAL NIHON)",
"WELDING ROD 6013 - 3/32 (GOLDEN BRIDGE)",
"WELDING ROD STAINLESS 3/32",
"HEAVY DUTY STEEL BRUSH",
"ACRYLON PAINT 7 REFILL",
"ACRYLON W/ HANDLE 7''",
"2B ACRYLON W/ HANDLE 7''",
"BABY ROLLER COTTON [HI-TECH] W/ HANDLE",
"BABY ROLLER FOAM [HI-TECH] W/ HANDLE",
"BABY ROLLER COTTON REFILL",
"BABY ROLLER FOAM REFILL",
"PAINT BRUSH 1/2",
"PAINT BRUSH 3/4",
"PAINT BRUSH 1",
"PAINT BRUSH 1.5",
"PAINT BRUSH 2",
"PAINT BRUSH 2.5",
"PAINT BRUSH 3",
"PAINT BRUSH 4",
"PAINT TRAY",
"STRAW",
"TANSI #60",
"TANSI #80",
"TANSI #90",
"TANSI #100",
"TEFLON 1/2",
"TEFLON 3/4",
"NYLON ROPE 10mm",
"CABLE TIE 5*200mm",
"PCS CABLE TIE 5*250mm",
"CABLE TIE 5*300mm",
"CABLE TIE 5*350mm",
"ELECTRICAL TAPE BIG",
"ELECTRICAL TAPE SMALL",
"ARMAK MASKING TAPE 1/2 (96/CRTN)",
"ARMAK MASKING TAPE 3/4 (64/CRTN)",
"ARMAK MASKING TAPE 1 (48/CRTN)",
"FIBER TAPE #2",
"MEASURING TAPE 3 METERS",
"MEASURING TAPE 5 METERS",
"MEASURING TAPE 7.5 METERS",
"GI CLAMP 1/2",
"GI CLAMP 3/4",
"GI CLAMP 1",
"ROYU QUADRUPLE CONV OUTLET",
"ROYU TRIPLE CONV OUTLET",
"PANASONIC GROUNDING DUPLEX UNIVERSAL",
"ROYU 1-GANG OUTLET SET WIDE SERIES",
"ROYU 2-GANG OUTLET SET WIDE SERIES",
"ROYU 3-GANG OUTLET SET WIDE SERIES",
"ROYU 2-GANG OUTLET SET CLASSIC",
"ROYU 3-GANG OUTLET SET CLASSIC",
"AIRCON OUTLET",
"1-GANG CLASSIC TEL MOD JACK ",
"1 GANG CABLE OUTLET",
"CABLE OUTLET ONLY",
"1 GANG TV TERMINAL",
"EAGLE #2082 DUPLEX TAP OUTLET",
"EAGLE #2083 TRIPLEX TAP OUTLET",
"EAGLE #3982 WEATHER PROOF OUTLET",
"PANASONIC 1-WAY CLASSIC SWITCH",
"ROYU 1-GANG SWITCH SET WIDE SERIES",
"ROYU 2-GANG SWITCH SET WIDE SERIES",
"ROYU 3-GANG SWITCH SET WIDE SERIES",
"1GANG 3WAY SWITCH ROYU",
"ROYU 1-GANG SWITCH SET CLASSIC",
"ROYU 3-GANG SWITCH SET CLASSIC",
"ROYU 3-WAY SWITCH ONLY",
"LED ENERGETIC DAY LIGHT 3W",
"LED ENERGETIC DAY LIGHT 7W",
"LED ENERGETIC DAY LIGHT 11W",
"ECOLUM LED BULB 3W DL E27",
"ECOLUM LED BULB 5W DL E27",
"ECOLUM LED BULB 7W DL E27",
"ECOLUM LED BULD 9W DL E27",
"ECOLUM LED BULB 11W DL E2",
"ECOLUM LED BULB 13W DL E2",
"ECOLUM LED BULB 15W DL E2",
"ECOLUM LED BULB 17W DL E2",
"ECOLUM LED BULB 19W DL E2",
"RECEPTACLE 2*2",
"RECEPTACLE 3*3",
"RECEPTACLE 4*4",
"GE BREAKER 15AMP PLUG-IN",
"GE BREAKER 20AMP PLUG-IN",
"GE BREAKER 30AMP PLUG-IN",
"GE BREAKER 40AMP PLUG-IN",
"GE BREAKER 60AMP PLUG-IN",
"GE BREAKER 70AMP PLUG-IN",
"GE BREAKER 100AMP PLUG-IN",
"GE BREAKER 15AMP BOLT-ON",
"GE BREAKER 20AMP BOLT-ON",
"GE BREAKER 30AMP BOLT-ON",
"GE BREAKER 60AMP BOLT-ON",
"GE BREAKER 70AMP  BOLT-ON",
"GE BREAKER 100AMP  BOLT-ON",
"ROYU CUIRCUIT BREAKER 15 PLUG-IN",
"ROYU CUIRCUIT BREAKER 20 PLUG-IN",
"ROYU CUIRCUIT BREAKER 30 PLUG-IN",
"ROYU CUIRCUIT BREAKER 40 PLUG-IN",
"ROYU CUIRCUIT BREAKER 60 PLUG-IN",
"ROYU CIRCUIT BREAKER 100 PLUG-IN",
"ROYU SAFETY BREAKER 15 AMPS BOLT-ON",
"ROYU SAFETY BREAKER 40 AMPS BOLT ON",
"ROYU SAFETY BREAKER 20 BOLT-ON",
"ROYU SAFETY BREAKER 30 BOLT-ON",
"ROYU SAFETY BREAKER 60 BOLT-ON",
"ROYU WEATHER PROOF PLATE WIDE ",
"PANASONIC 2-GANG PLATE CLASSIC",
"PANASONIC 3-GANG PLATE CLASSIC",
"PANASONIC 2-GANG PLATE WIDE SERIES",
"PANASONIC 3-GANG PLATE WIDE SERIES",
"ROYU 1-GANG PLATE CLASSIC",
"ROYU 2-GANG PLATE CLASSIC",
"ROYU 3-GANG PLATE CLASSIC",
"ROYU 1-GANG PLATE WIDE SERIES",
"ROYU 2-GANG PLATE WIDE SERIES",
"ROYU 3-GANG PLATE WIDE SERIES",
"ROYU BLANK PLATE",
"NELTEX JUNCTION BOX",
"NELTEX UTILITY BOX",
"NEMA 1 PLUG IN",
"NEMA 3R BOLT ON",
"NEMA 3R PLUG IN",
"PANEL BOX 2 BRANCHES",
"PANEL BOX 4 BRANCHES",
"PANEL BOX 6 BRANCHES",
"PANEL BOX 8 BRANCHES",
"PANEL BOX 10 BRANCHES",
"PANEL BOX 12 BRANCHES",
"JUNCTION BOX",
"JUNCTION BOX COVER",
"AMCO BOX",
"UTILITY BOX",
"PVC MOULDING 1/2",
"PVC MOULDING 3/4",
"PVC MOULDING 5/8",
"PVC MOULDING 1",
"PVC ATLANTA MOULDING 1/2",
"PVC ATLANTA MOULDING 3/4",
"ATLANTA MOULDING 5/8",
"PVC ATLANTA MOULDING 1",
"ROYU UNIVERSAL ADAPTOR",
"RUBBER PLUGS 121 EAGLE SMALL",
"EAGLE 101 HEAVY DUTY RUBBER",
"ORANGE PIPE 1/2 ATLANTA",
"ORANGE PIPE ATLANTA 3/4",
"ORANGE PIPE ATLANTA 1",
"ORANGE PIPE ASIA 1/2",
"ORANGE PIPE ASIA 3/4",
"ORANGE PIPE ASIA 1",
"ORANGE PIPE NELTEX 1/2",
"ORANGE PIPE NELTEX 3/4",
"ORANGE PIPE NELTEX 1",
"NELTEX  LONG ELBOW 1",
"NELTEX  LONG ELBOW 1/2",
"NELTEX  LONG ELBOW 3/4",
"LONG ELBOW 1 ATLANTA",
"LONG ELBOW 1/2 ATLANTA",
"ORANGE FITTING MALE ADAPTOR W/LOCK NUT 1/2",
"ORANGE FITTING MALE ADAPTOR W/LOCK NUT 1/2",
"ORANGE FITTING MALE ADAPTOR W/LOCK NUT 3/4",
"ORANGE FITTINGS LONG ELBOW ATLANTA 3/4",
"ORANGE FLEXIBLE CONNECTOR 1/2",
"ORANGE FLEXIBLE CONNECTOR 3/4",
"ORDINARY BLUE FITTINGS MALE PLUG 1",
"ORDINARY BLUE FITTINGS MALE PLUG 1/2",
"ORDINARY BLUE FITTINGS MALE PLUG 3/4",
"PE HOSE 1/2 BLUE (SDR 11)",
"FLEXIBLE HOSE 1/2 EURO",
"FLEXIBLE HOSE 1/2 EURO 100mtrs",
"FLEXIBLE HOSE 1/2 METAL",
"FLEXIBLE HOSE 3/4",
"FLEXIBLE HOSE 1",
"ROYU WIRE #14 STR (2.0MM)",
"ROYU WIRE #12 STR (3.5MM)",
"ROYU WIRE #10 STR (5.5MM)",
"ROYU WIRE #8 STR (8.0MM)",
"PHELPDODGE THHN 12/7 (3.5MM)",
"PHELPDODGE THHN 10/7 (5.5MM)",
"PHELPDODGE THHN 8/7 (8.0)",
"PHELPDODGE THHN 14/7 (2.0)",
"FLAT CORD #16",
"FLAT CORD #18",
"FLAT CORD #22",
"POWERFLEX THHN #14 STR (2.0MM)",
"POWERFLEX THHN #12 STR (3.5MM)",
"POWERFLEX THHN #10 STR (5.5MM)",
"POWERFLEX THHN #8 STR (8.0MM)",
"INSULATED STAPLE WIRE 1",
"INSULATED STAPLE WIRE 1/2 (100PCS)",
"INSULATED STAPLE WIRE 3/4",
"ORANGE CLAMP 1",
"ORANGE CLAMP 1/2",
"ORANGE CLAMP 3/4",
"ELECTRIC METER",
"1L CONCRETE NEUTRALIZER",
"GAL CONCRETE NEUTRALIZER",
"SOLIGNUM BROWN (LITRO)",
"SOLIGNUM CLEAR (LITRO)",
"SOLIGNUM BROWN (GALLON)",
"SOLIGNUM CLEAR (GALLON)",
"SOLIGNUM SOILGUARD (LITRO)",
"FULATITE 1/4L",
"FULATITE 1/2L",
"FULATITE 1L",
"DELAWARE AUTOMOTIVE LACQUER PUTTY WHITE",
"DELAWARE AUTOMOTIVE LACQUER WHITE",
"LIT. SPHERO AUTOMOTIVE LACQUER WHITE",
"GAL. SPHERO AUTOMOTIVE LACQUER PRIMER SURFACER WHITE",
"GAL. SPHERO AUTOMOTIVE LACQUER WHITE",
"TIN LEGEND LACQUER THINNER",
"GAL (3L) LEGEND PAINT THINNER",
"GAL (3L) LEGEND LACQUER THINNER",
"BT LEGEND LACQUER THINNER [24/BX]",
"BT LEGEND PAINT THINNER [24/BX]",
"POLITUF WITH HARDENER GAL",
"POLITUF WITH HARDENER LIT",
"LITRO PINNACLE EPOXY PRIMER GRAY",
"LITRO PINNACLE EPOXY PRIMER WHITE",
"GAL PINNACLE EPOXY PRIMER GRAY",
"GAL PINNACLE EPOXY PRIMER WHITE",
"PACK STIKWELL",
"1/2L STIKWELL",
"1L STIKWELL",
"GALLON STIKWELL",
"STEEL EPOXY 15g",
"SMALL RUGBY 45ML",
"BOT RUGBY ORGINAL 300ML",
"GAL RUGBY ORIGINAL",
"1/4PT MARINE EPOXY 1&B",
"1/2PT MARINE EPOXY 1&B",
"MARINE EPOXY 1 PINT",
"MARINE EPOXY 1 LITER",
"MIGHTY BOND (PIONEER) 3g",
"CONC. EPOXY H.V A&B PIONEER",
"1/4PINT EPOXY A&B [PIONEER]",
"1/2PINT EPOXY A&B [PIONEER]",
"1 PINT EPOXY A&B [PINONEER]",
"1 LIT. EPOXY A&B [PIONEER",
"NON SAG EPOXY PIONEER A&B 1/4 PINT",
"NON SAG EPOXY PIONEER A&B 1/2 PINT",
"NON SAG EPOXY PIONEER A&B 1 PINT",
"NON SAG EPOXY PIONEER A&B LIT",
"BOSTIK NO MORE NAILS 30G",
"BOSTIK NO MORE NAILS 100G",
"BOSTIK NO MORE NAILS 320G",
"GAL.BOSTIK POWERMIX",
"TIN BOSTIK POWERMIX",
"BOTTLE DENATURED ALCOHOL",
"BOTTLE STRIPSOL",
"GALLON STRIPSOL",
"(LIT) VALSPAR",
"(GAL) VALSPAR",
"POLYURETHANE TOPCOAT GAL",
"POLYURETHANE TOPCOAT LIT",
"VARNISH BROWN BOTTLE",
"VARNISH MAHOGANY BOTTLE",
"VARNISH MAPLE BOTTLE",
"VARNISH NATURAL BOTTLE",
"VARNISH WALLNUT BOTTLE_",
"LIT. SPHERO SANDING SEALER",
"GAL THOROSEAL GRAY",
"PROSIL CLEAR",
"PROSIL GRAY",
"SILICON STELLAR",
"ELASTOSEAL 250G",
"ELASTOSEAL 85G",
"PACK VULCASEAL",
"1/4L VULCASEAL",
"1/2L VULCASEAL",
"LIT VULCASEAL",
"100CC NELTEX SOLVENT",
"200CC NELTEX SOLVENT",
"400CC NELTEX SOLVENT",
"TURCO RUST CONVERTER GAL",
"TURCO RUST CONVERTER LIT",
"LIT MURIATIC ACID",
"METAL FLEXIBLE 1/2*30M",
"METAL CLIP",
"METAL CLIP COLORED",
"KILO BASAHAN",
"SACK BIG",
"BARREL BOLT 2 (2B)",
"BARREL BOLT 3 (2B)",
"BARREL BOLT 4 (2B)",
"CHROME BARREL BOLT 2 (DIAMOND)",
"YALE LOCKSET",
"PADLOCK 30mm short",
"PADLOCK 40mm short",
"PADLOCK 50mm short",
"PVC DOOR 60*210",
"DOOR KNOB ORD.",
"DOOR PAD",
"SHELF BRACKET 5*6",
"SHELF BRACKET 6*8",
"SHELF BRACKET 8*10",
"DRAWER GUIDE 16",
"DRAWER GUIDE 20",
"DRAWER GUIDE 24",
"CAULKING GUN BLUE",
"CEMENT PAIL #12",
"CEMENT TROWEL #7",
"CEMENT TROWEL 9",
"CHALK STONE",
"HACKSAW BLADE ( SANDFLEX)",
"HACKSAW BLADE WITH FRAME",
"HAND RIVETER",
"HAMMER",
"PALITA 4",
"PALITA 6",
"PLASTERING TROWEL",
"PUTTY KNIFE 2",
"PUTTY KNIFE 3",
"PUTTY KNIFE 6",
"SHOVEL",
"SILICON GUN",
"COTTON GLOVES 2B",
"LATEX ORANGE GLOVES",
"SAFETY GOGGLES",
"LEVEL HOSE 1/4",
"LEVEL HOSE 3/8",
"STAINLESS STRAINER 4*4 CHICAGO",
"KITCHEN SINK S/S 14*20",
"KITCHEN SINK S/S 16*24",
"FAUCET PLAIN PVC",
"WATER METER [Digital]",
"TOILET BOWL",
"CUT OFF WHEEL STELLAR 14",
"CUTTING DISK",
"DIAMOND CUTTING WHEEL DRY",
"DIAMOND CUTTING WHEEL TURBO 4 SUPERTHIN",
"FLAT DISC #60 2B",
"FLAT DISC #80 2B",
"FLAT DISC #100 2B",
"FLAT DISC #120 2B",
"TYROLIT CUTTING #4 THIN",
"TYROLIT GRINDING #4",
"OASIS GRINDING DISK 4",
"SAND PAPER 36",
"SAND PAPER 60",
"SAND PAPER 80",
"SAND PAPER 100",
"PC SAND PAPER 60",
"PC SAND PAPER 80",
"PC SAND PAPER 100",
"PC SAND PAPER 120",
"PC SAND PAPER 150",
"PC SAND PAPER 180",
"PC SAND PAPER 220",
"PC SAND PAPER 240",
"PC SAND PAPER 280",
"PC SAND PAPER 320",
"PC SAND PAPER 360",
];

// Units for item ordering
const UNITS = ['PIECES', 'BAGS', 'DOZEN', 'BUNDLES', 'CRATES', 'ROLLS', 'BOXES', 'TRUCKLOAD'];

// Credentials (simplified for this example)
const CREDENTIALS = {
  "Dan": 123,
  "Jun": 111,
  "Kyle": 222,
  "Regie": 333,
  "Isaias": 444,
  "Roldan": 555,
  "Mark": 666,
  "Norie": 777,
  "Rolex": 888,
  "Ace": 999,
};

// Replace with your actual Discord webhook URL
const DISCORD_WEBHOOK_URL = 'https://discord.com/api/webhooks/1313060263758401576/osZFkmsGi5BMzVcufsm6TTRyS1q8c3_wuZFyUUR81U31i22FLcZkmCEjMbJyWMQXCsmi';

// Utility function to generate random alphanumeric reference codes
const generateReferenceCode = () => {
  return Math.random().toString(36).substring(2, 10).toUpperCase();
};

function InventoryOrderApp() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [items, setItems] = useState(INVENTORY_ITEMS);
  const [orderList, setOrderList] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [loginCredentials, setLoginCredentials] = useState({ username: '', password: '' });
  const [currentOrder, setCurrentOrder] = useState(null);
  const [showUnitSelect, setShowUnitSelect] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    if (searchQuery) {
      const filtered = items.filter(item => 
        item.toLowerCase().includes(searchQuery.toLowerCase())
      ).slice(0, 10);
      setFilteredItems(filtered);
    } else {
      setFilteredItems([]);
    }
  }, [searchQuery, items]);

  const handleLogin = () => {
    const { username, password } = loginCredentials;
    const parsedPassword = parseInt(password);

    if (CREDENTIALS[username] && CREDENTIALS[username] === parsedPassword) {
      setIsLoggedIn(true);
      setUsername(username);
    } else {
      alert('Mali Password mo!');
    }
  };

  const handleAddItemToOrder = (item) => {
    setCurrentOrder({ item, quantity: "" });
    setShowUnitSelect(true);
    setSearchQuery('');
  };

  const handleQuantityChange = (e) => {
    const quantity = parseInt(e.target.value) || "";
    setCurrentOrder(prev => ({ ...prev, quantity }));
  };

  const handleUnitSelect = (unit) => {
    if (currentOrder) {
      const newOrderItem = {
        item: currentOrder.item,
        quantity: currentOrder.quantity,
        unit
      };

      setOrderList(prev => [...prev, newOrderItem]);
      setCurrentOrder(null);
      setShowUnitSelect(false);
    }
  };

  const handleRemoveOrderItem = (indexToRemove) => {
    setOrderList(prev => prev.filter((_, index) => index !== indexToRemove));
  };

  const sendDiscordWebhook = async () => {
    const currentDate = new Date();
    const formattedDateTime = currentDate.toLocaleString();
    const referenceCode = generateReferenceCode();

    const orderListMessage = orderList.map((item, index) => 
      `${index + 1}. ${item.item} | ${item.quantity} ${item.unit}`
    ).join('\n');

    const fullMessage = 
      `**CHECKER NAME: ${username}**\n` +
      `**Date and Time:** ${formattedDateTime}\n` +
      `**Reference Code:** ${referenceCode}\n\n` +
      `**ORDER LIST:**\n${orderListMessage}`;

    try {
      const response = await fetch(DISCORD_WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: fullMessage
        })
      });

      if (response.ok) {
        alert('Ang order mo ay na send na kay Purchasing!');
        setOrderHistory(prev => [...prev, { 
          referenceCode, 
          date: formattedDateTime, 
          orderList: [...orderList], 
          checker: username 
        }]);
        setOrderList([]);
      } else {
        alert('Failed to send order to Purchasing.');
      }
    } catch (error) {
      console.error('Error sending webhook:', error);
      alert('Error sending order to Discord.');
    }
  };

  const handleConfirmOrder = () => {
    if (orderList.length === 0) {
      alert('Your order list is empty');
      return;
    }

    setShowConfirmationModal(true);
  };

  const handleConfirmationAction = (confirm) => {
    setShowConfirmationModal(false);

    if (confirm) {
      sendDiscordWebhook();
    } else {
      alert('Ang Order ay Hindi na send dahil sa pag cancel.');
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md w-96">
          <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
          <input 
            type="text"
            placeholder="Username"
            value={loginCredentials.username}
            onChange={(e) => setLoginCredentials(prev => ({
              ...prev, 
              username: e.target.value
            }))}
            className="w-full p-2 mb-4 border rounded"
          />
          <input 
            type="password"
            placeholder="Password"
            value={loginCredentials.password}
            onChange={(e) => setLoginCredentials(prev => ({
              ...prev, 
              password: e.target.value
            }))}
            className="w-full p-2 mb-4 border rounded"
          />
          <button 
            onClick={handleLogin}
            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
          >
            Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Warehouse Inventory Order System</h1>
          <div className="flex items-center">
            <span className="mr-4">Welcome, {username}</span>
            <button 
              onClick={() => setIsLoggedIn(false)}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              Logout
            </button>
          </div>
        </div>

        {/* Search and Add Item Section */}
        <div className="mb-6 relative">
          <input 
            type="text"
            placeholder="Search items..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 border rounded"
          />
          {filteredItems.length > 0 && (
            <ul className="absolute z-10 w-full bg-white border rounded mt-1 max-h-60 overflow-y-auto">
              {filteredItems.map((item, index) => (
                <li 
                  key={index} 
                  onClick={() => handleAddItemToOrder(item)}
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Unit Selection Modal */}
        {showUnitSelect && currentOrder && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg">
              <h3 className="text-xl mb-4">Select Quantity and Unit for {currentOrder.item}</h3>
              <div className="mb-4">
                <label className="block mb-2">Quantity</label>
                <input 
                  type="number" 
                  min="1" 
                  value={currentOrder.quantity} 
                  onChange={handleQuantityChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                {UNITS.map((unit) => (
                  <button
                    key={unit}
                    onClick={() => handleUnitSelect(unit)}
                    className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                  >
                    {unit}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Confirmation Modal */}
        {showConfirmationModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg text-center">
              <h3 className="text-xl mb-4">Confirm Order</h3>
              <p className="mb-4">Are you sure you want to send this order?</p>
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={() => handleConfirmationAction(true)}
                  className="bg-green-500 text-white px-4 py-2 rounded"
                >
                  Yes, Send Order
                </button>
                <button 
                  onClick={() => handleConfirmationAction(false)}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  No, Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Order List Section */}
        <div className="bg-gray-50 p-4 rounded">
          <h2 className="text-xl font-semibold mb-4">Current Order</h2>
          {orderList.length === 0 ? (
            <p className="text-gray-500">No items in order</p>
          ) : (
            <ul>
              {orderList.map((item, index) => (
                <li 
                  key={index} 
                  className="flex justify-between items-center p-2 border-b"
                >
                  <span>{item.item} | {item.quantity} {item.unit}</span>
                  <button 
                    onClick={() => handleRemoveOrderItem(index)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          )}

          {/* Order Confirmation Buttons */}
          <div className="flex justify-between mt-4">
            <button 
              onClick={() => setOrderList([])}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Clear Order
            </button>
            <button 
              onClick={handleConfirmOrder}
              className="bg-green-500 text-white px-4 py-2 rounded"
              disabled={orderList.length === 0}
            >
              Confirm Order
            </button>
          </div>
        </div>

        {/* Order History Section */}
        <div className="bg-gray-50 p-4 rounded mt-6">
          <h2 className="text-xl font-semibold mb-4">Order History</h2>
          {orderHistory.length === 0 ? (
            <p className="text-gray-500">No past orders</p>
          ) : (
            <ul>
              {orderHistory.map((order, index) => (
                <li key={index} className="p-2 border-b">
                  <p><strong>Reference Code:</strong> {order.referenceCode}</p>
                  <p><strong>Date:</strong> {order.date}</p>
                  <p><strong>Checker:</strong> {order.checker}</p>
                  <p><strong>Order List:</strong></p>
                  <ul className="pl-4">
                    {order.orderList.map((item, idx) => (
                      <li key={idx}>{item.item} | {item.quantity} {item.unit}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default InventoryOrderApp;
